<template>
  <div class="home__top--background-img" :style="backgroundImg">
    <div class="home__top--background-color" :style="backgroundColor">
      <v-container class="mobile-home-view__top">
        <v-row no-gutters>
          <v-col cols="12" class="d-flex align-center justify-center">
            <div
              class="d-flex justify-center align-center home__top--image-container"
            >
              <v-img contain :src="getLogoSRC" max-height="100%" />
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="d-flex justify-center align-center white--text">
              <span class="font-weight-medium">{{
                trans("company_slogan_text")
              }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mobile-home-view__center">
        <v-row no-gutters>
          <v-col cols="12">
            <div class="spacer" />
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mobile-home-view__bottom">
        <v-row no-gutters class="mb-4">
          <v-col cols="12">
            <mobile-button
              background="#272727"
              :text="trans('go_to_login')"
              strong
              size="14px"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            class="d-flex justify-center align-center white--text mb-8"
          >
            <div class="not-account">
              <span>{{ trans("login_signup_question") }}</span>
              <span class="font-weight-medium">{{
                trans("sign_up_button")
              }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            class="d-flex justify-center align-center white--text"
          >
            <span class="font-weight-medium">{{ trans("not_now") }}</span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileButton from "../components/MobileButton.vue";

export default {
  name: "MobileHomeView",
  components: { MobileButton },
  mixins: [mobilePreviewActions],
  computed: {
    backgroundImg() {
      if (!this.config("tenants_mobile_image_home")) return {};
      return {
        backgroundImage: `url(${
          this.config("image_base_url") +
          this.config("tenants_mobile_image_home").name
        })`,
        borderRadius: `${this.getPreviewConfiguration.radiusDisplay}rem`,
      };
    },
    backgroundColor() {
      return {
        backgroundColor: this.$helpers.hexToRgba(
          this.config("tenants_mobile_color_primary"),
          0.4
        ),
        borderRadius: `${this.getPreviewConfiguration.radiusDisplay}rem`,
      };
    },
    getLogoSRC() {
      if (!this.config("tenants_mobile_image_logo")) return;
      return (
        this.config("image_base_url") +
        this.config("tenants_mobile_image_logo").name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.home__top--background-img {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__top--background-color {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 0 1rem 0;
}

.home__top--background-img {
  pointer-events: none;
}

span {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.not-account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spacer {
  height: 50%;
  width: 100%;
}

.mobile-home-view__bottom .col {
  display: flex;
  justify-content: center;
}

.home__top--image-container {
  width: 250px;
  height: 125px;
  margin-bottom: 22px;
}
</style>
